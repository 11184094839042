import { IComponentController } from '@wix/editor-elements-types';
import { IFormContainerHoneypotActions } from '../FormContainer.types';

const mapActionsToProps: IComponentController = ({
  updateProps,
}): IFormContainerHoneypotActions => ({
  onHoneypotChange: honeypotValue => {
    updateProps({
      honeypotValue,
    });
  },
});

export default mapActionsToProps;
